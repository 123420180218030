<template>
  <div v-click-outside="clickOutside" class="mr-12 ml-6 relative w-48 body-font">
    <p
      class="neutral-background-4 px-4 py-2 card-border-radius cursor-pointer font-semibold body-font"
      @click="changeDate = true"
    >
      {{ dateFilter }}
    </p>
    <div
      v-show="changeDate"
      class="absolute w-full neutral-background-4 p-4 card-border-radius mt-4 border-neutral border shadow-md"
    >
      <p class="py-2 border-b border-neutral cursor-pointer" @click="updateDate($t('filter.date.1'))">
        {{ $t("filter.date.1") }}
      </p>
      <p class="py-2 border-b border-neutral cursor-pointer" @click="updateDate($t('filter.date.2'))">
        {{ $t("filter.date.2") }}
      </p>
      <p class="py-2 border-b border-neutral cursor-pointer" @click="updateDate($t('filter.date.3'))">
        {{ $t("filter.date.3") }}
      </p>
      <p class="pt-2 cursor-pointer" @click="updateDate($t('filter.date.4'))">
        {{ $t("filter.date.4") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateFilter",
  data() {
    return {
      dateFilter: this.$t("filter.date.1"),
      changeDate: false
    };
  },

  watch: {
    dateFilter() {
      this.$emit("date-changed", this.dateFilter);
    }
  },

  methods: {
    clickOutside() {
      if (this.changeDate) {
        this.changeDate = false;
      }
    },

    updateDate(date) {
      this.dateFilter = date;
      this.changeDate = false;
    }
  }
};
</script>

<style scoped></style>
