<template>
  <h3 class="text-2 font-bold main-font primary-text">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: "LargeBold"
};
</script>

<style scoped></style>
