import axios from "axios";

export default class SessionsClient {
  static async getSessions() {
    return await axios.get("/api/coach/sessions");
  }

  static async getAppointment(id) {
    return await axios.get(`/api/coach/appointments/${id}`);
  }

  static async getPastAppointments(page, status, start, end) {
    const statusQuery = status.length > 0 ? "&byStatus=" + status : "";
    const startQuery = start.length > 0 ? "&byStart=" + start : "";
    const endQuery = end.length > 0 ? "&byEnd=" + end : "";

    return await axios.get(`/api/coach/appointments-past?page=${page}${statusQuery}${startQuery}${endQuery}`);
  }

  static async createSessions(sessions) {
    return await axios.post("/api/coach/sessions", sessions);
  }

  static async deleteSession(id) {
    return await axios.delete(`/api/coach/sessions/${id}`);
  }

  static async getVideoRoom(id) {
    return await axios.get(`/api/v2/coach/appointments/${id}/connect`);
  }

  static async getAppointments() {
    return await axios.get("/api/coach/appointments");
  }

  static async getAcceptedAppointments() {
    return await axios.get("/api/coach/appointments-future?byStatus=accepted");
  }

  static async getCanceledAppointments() {
    return await axios.get("/api/coach/appointments-future");
  }

  static async cancelAppointment(params) {
    return await axios.post(`/api/coach/appointments/${params.id}/cancel`, { reason: params.reason });
  }

  static async rateAppExperience(id, rate, note) {
    return await axios.post(`/api/appointments/${id}/feedback`, { rate, note });
  }
}
