<template>
  <button
    :class="sm ? 'px-3 py-1 text-sm font-normal ' : 'px-6 py-2 font-bold'"
    :disabled="disabled"
    class="button-border-radius outline-none body-font highlight-bg cta-button-case"
    @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    disabled: Boolean,
    sm: Boolean
  }
};
</script>

<style scoped>
button {
  @apply transition-all duration-300;
  background-color: var(--primary-background-active);
  color: var(--primary-button-text);
}

button:active {
  background-color: var(--primary-background-active);
}

button:focus {
  outline: none;
}

button:disabled {
  background-color: var(--primary-background-disabled);
  color: var(--disabled-button-text);
}
</style>
