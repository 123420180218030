<template>
  <div id="app">
    <LanguageModal />
    <notifications group="notifications" />
    <router-view />
  </div>
</template>
<script>
import storageClient from "@/services/storageClient";
import LanguageModal from "@/components/LanguageModal";

export default {
  name: "App",
  components: { LanguageModal },
  async created() {
    if (storageClient.getLanguage()) {
      await this.$store.dispatch("locale/setLocale", storageClient.getLanguage());
    } else {
      storageClient.setLanguage("en-US");
    }

    // const { data } = await axios.post("/api/coach/auth/forgot-password", { email: "qa+1@nerdzlab.com" });
    //
    // console.log(data);
  },
  mounted() {
    document.documentElement.style.setProperty("--card-border-radius", process.env.VUE_APP_CARD_BORDER_RADIUS);
    document.documentElement.style.setProperty("--button-border-radius", process.env.VUE_APP_BUTTON_BORDER_RADIUS);

    document.documentElement.style.setProperty("--neutral-background-1", process.env.VUE_APP_NEUTRAL_BACKGROUND_1);
    document.documentElement.style.setProperty("--neutral-background-2", process.env.VUE_APP_NEUTRAL_BACKGROUND_2);
    document.documentElement.style.setProperty("--neutral-background-3", process.env.VUE_APP_NEUTRAL_BACKGROUND_3);
    document.documentElement.style.setProperty("--neutral-background-4", process.env.VUE_APP_NEUTRAL_BACKGROUND_4);
    document.documentElement.style.setProperty("--neutral-background-5", process.env.VUE_APP_NEUTRAL_BACKGROUND_5);
    document.documentElement.style.setProperty("--primary-background", process.env.VUE_APP_PRIMARY_BACKGROUND);
    document.documentElement.style.setProperty("--primary-button-text", process.env.VUE_APP_PRIMARY_BUTTON_TEXT);
    document.documentElement.style.setProperty("--primary-border-color", process.env.VUE_APP_PRIMARY_BACKGROUND);
    document.documentElement.style.setProperty("--inactive-text", process.env.VUE_APP_INACTIVE_TEXT);
    document.documentElement.style.setProperty("--primary-text", process.env.VUE_APP_PRIMARY_TEXT);
    document.documentElement.style.setProperty("--disabled-button-text", process.env.VUE_APP_DISABLED_BUTTON_TEXT);
    document.documentElement.style.setProperty("--highlight", process.env.VUE_APP_HIGHLIGHT);
    document.documentElement.style.setProperty("--tag-border-radius", process.env.VUE_APP_TAG_BORDER_RADIUS);
    document.documentElement.style.setProperty("--global-border-radius", process.env.VUE_APP_GLOBAL_BORDER_RADIUS);
    document.documentElement.style.setProperty("--interactive-text", process.env.VUE_APP_INTERACTIVE_TEXT);
    document.documentElement.style.setProperty("--interactive-graphics", process.env.VUE_APP_INTERACTIVE_GRAPHICS);
    document.documentElement.style.setProperty("--inactive-graphics", process.env.VUE_APP_INACTIVE_GRAPHICS);
    document.documentElement.style.setProperty("--cta-button-case", process.env.VUE_APP_CTA_BUTTON_CASE);
    document.documentElement.style.setProperty("--secondary-button-text", process.env.VUE_APP_SECONDARY_BUTTON_TEXT);
    document.documentElement.style.setProperty(
      "--primary-background-active",
      process.env.VUE_APP_PRIMARY_BACKGROUND_ACTIVE
    );
    document.documentElement.style.setProperty(
      "--primary-background-disabled",
      process.env.VUE_APP_PRIMARY_BACKGROUND_DISABLED
    );

    document.documentElement.style.setProperty("--inactive-tab", process.env.VUE_APP_TAB_INACTIVE);
    document.documentElement.style.setProperty("--input-border-color", process.env.VUE_APP_INPUT_BORDER_COLOR);
    document.documentElement.style.setProperty(
      "--input-placeholder-color",
      process.env.VUE_APP_INPUT_PLACEHOLDER_COLOR
    );
    document.documentElement.style.setProperty("--input-border-radius", process.env.VUE_APP_INPUT_BORDER_RADIUS);

    const defaultFont = "Manrope, sans-serif";

    if (process.env.VUE_APP_FONT_MAIN) {
      const mainFont = new FontFace("Main Font", `url(${process.env.VUE_APP_FONT_MAIN})`);
      mainFont
        .load()
        .then((loaded_face) => {
          document.fonts.add(loaded_face);
          document.documentElement.style.setProperty("--main-font", "Main Font");
        })
        .catch((error) => {
          console.error(error);
          document.documentElement.style.setProperty("--main-font", defaultFont);
        });
    } else {
      document.documentElement.style.setProperty("--main-font", defaultFont);
    }

    if (process.env.VUE_APP_FONT_BODY) {
      const bodyFont = new FontFace("Body Font", `url(${process.env.VUE_APP_FONT_BODY})`);
      bodyFont
        .load()
        .then((loaded_face) => {
          document.fonts.add(loaded_face);
          document.documentElement.style.setProperty("--body-font", "Body Font");
        })
        .catch((error) => {
          console.error(error);
          document.documentElement.style.setProperty("--body-font", defaultFont);
        });
    } else {
      document.documentElement.style.setProperty("--body-font", defaultFont);
    }
  }
};
</script>
<style>
.daterangepicker.show-calendar .ranges {
  display: none !important;
}

@media screen and (min-width: 339px) {
  .daterangepicker.single.show-ranges.show-weeknumbers,
  .daterangepicker.single.show-ranges {
    min-width: 290px !important;
  }
}

.reportrange-text {
  border: none !important;
  border-radius: 10px;
  padding: 10px 12px !important;
  text-align: center !important;
}
</style>
