<template>
  <div class="body-font">
    <div :class="{ 'input-error': errors.length > 0 }" class="input-wrapper relative">
      <slot></slot>
      <span v-if="password" class="absolute eye-position cursor-pointer" @click="changeStatus">
        <span v-if="!isOpen">
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.5 4C4.96102 4 1 9.5 1 9.5C1 9.5 4.96102 15 10.5 15C16.039 15 20 9.5 20 9.5C20 9.5 16.039 4 10.5 4Z"
              class="stroke-main"
              stroke-width="1.3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.5 13C12.433 13 14 11.433 14 9.5C14 7.567 12.433 6 10.5 6C8.567 6 7 7.567 7 9.5C7 11.433 8.567 13 10.5 13Z"
              class="stroke-main"
              stroke-width="1.3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span v-else>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              class="stroke-main"
              d="M13.2373 7.67142C12.5113 6.96292 11.5267 6.56488 10.5 6.56488C9.47332 6.56488 8.48869 6.96292 7.76271 7.67142C7.03674 8.37993 6.62889 9.34087 6.62889 10.3428C6.62889 11.3448 7.03674 12.3058 7.76271 13.0143M8.53559 15.78C9.1794 15.9276 9.83866 16.0015 10.5 16C16.039 16 20 10.5 20 10.5C19.1217 9.35721 18.0931 8.33222 16.9407 7.45142L8.53559 15.78ZM14.8475 6.1C13.5111 5.39484 12.0191 5.01733 10.5 5C4.96102 5 1 10.5 1 10.5C2.33822 12.2389 4.0257 13.6933 5.95932 14.7743L14.8475 6.1ZM10.5 14.1143C11.5249 14.1143 12.5078 13.7169 13.2325 13.0096C13.9573 12.3024 14.3644 11.3431 14.3644 10.3428L10.5 14.1143Z"
              stroke-width="1.3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20 1L1 20"
              class="stroke-main"
              stroke-width="1.3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </span>
    </div>
    <p v-if="errors.length > 0" class="text-error text-13 ml-6 mt-1">{{ errors[0] }}</p>
  </div>
</template>

<script>
export default {
  name: "MainInput",
  props: {
    errors: {
      default() {
        return [];
      }
    },
    password: Boolean
  },

  data() {
    return {
      isOpen: false
    };
  },

  methods: {
    changeStatus() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit("change-to-text");
      } else {
        this.$emit("change-to-password");
      }
    }
  }
};
</script>

<style scoped>
.input-wrapper input {
  @apply w-full px-6 py-3 border transition-all duration-300;
  border-radius: var(--input-border-radius);
  outline: none;
  border-color: var(--input-border-color);
}
.input-wrapper input::placeholder {
  color: var(--input-placeholder-color);
}
.input-wrapper input:focus {
  border-color: var(--primary-border-color);
}
.input-error.input-wrapper input {
  @apply border-error;
}
.eye-position {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
