<template>
  <div class="h-full">
    <div v-show="videoOn" id="localTrack" class="relative z-50">
      <video id="video-element" ref="videoElement" />
    </div>
    <div class="w-full flex flex-row justify-center items-center h-full">
      <div id="remoteTrack" class="flex-1">
        <video
          id="video-element-another"
          v-show="isParticipantConnected"
          class="object-cover w-full h-full z-40"
          ref="videoElementAnother"
        />
        <div v-if="!isParticipantConnected" class="flex justify-center items-center h-f">
          <p class="text-center">{{ $t("video.waiting", { name: activeAppointment.client_full_name }) }}</p>
        </div>
        <div
          v-if="!remoteAudioEnabled && isParticipantConnected"
          class="audio-disabled px-2 py-1 pr-3 rounded-md text-white flex items-center"
        >
          <span class="mr-2">
            <svg height="20" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z"
                fill="#D82E2C"
              />
            </svg>
          </span>
          {{ activeAppointment.client_full_name }} {{ $t("video.is.muted") }}
        </div>
        <div
          v-if="true && isParticipantConnected"
          class="w-full bg-inactiveGraphics h-f absolute z-30 flex justify-center items-center"
        >
          <div class="flex flex-col justify-center items-center">
            <p class="text-white text-center px-3">
              {{ activeAppointment.client_full_name || "Client" }} {{ $t("video.disabled.camera") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <audio id="audio-element" controls class="hidden" ref="audioElement" />

    <div class="absolute bg-black bg-opacity-50 flex rounded-xl video-controls z-40">
      <div class="py-4 px-6 cursor-pointer" @click="changeAudio">
        <img v-if="audioOn" alt="on" class="h-8 w-8" src="../assets/images/video/micro-on.svg" />
        <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/micro-off.svg" />
      </div>
      <div class="py-4 px-6 cursor-pointer" @click="changeVideo">
        <img v-if="videoOn" alt="on" class="h-8 w-8" src="../assets/images/video/video-on.svg" />
        <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/video-off.svg" />
      </div>
      <div class="h-16 w-20 flex justify-center items-center">
        <div class="px-2 rounded-md bg-red-600 flex justify-center items-center py-1 cursor-pointer" @click="leaveCall">
          <img alt="on" src="../assets/images/video/phone.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration
} from "amazon-chime-sdk-js";

export default {
  name: "VideoSession",
  data() {
    return {
      videoRoom1: {
        access_token: "",
        sid: ""
      },
      activeRoom: null,
      localVideoTracks: null,
      showInputs: true,
      remoteVideoEnabled: false,
      remoteAudioEnabled: true,
      isParticipantConnected: false,
      audioOn: false,
      videoOn: false,
      meetingSession: null
    };
  },

  //todo check initial remote audio enabled

  computed: {
    ...mapState("sessions", ["videoRoom", "activeAppointment"])
  },

  methods: {
    async createAwsChimeVideo() {
      const logger = new ConsoleLogger("MyLogger", LogLevel.INFO);
      const deviceController = new DefaultDeviceController(logger);

      const configuration = new MeetingSessionConfiguration(this.videoRoom.room, this.videoRoom.attendee);

      const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

      const audioElement = this.$refs.audioElement;
      const videoElement = this.$refs.videoElement;
      const videoElementAnother = this.$refs.videoElementAnother;

      meetingSession.audioVideo.bindAudioElement(audioElement);

      const devices = await meetingSession.audioVideo.listAudioInputDevices();

      await meetingSession.audioVideo.startAudioInput(devices[0].deviceId);

      const attendeePresenceSet = new Set();
      const callback = (presentAttendeeId, present) => {
        if (present) {
          if (presentAttendeeId != this.videoRoom.attendee.AttendeeId) {
            this.isParticipantConnected = true;
          }
          attendeePresenceSet.add(presentAttendeeId);
        } else {
          this.isParticipantConnected = false;
          attendeePresenceSet.delete(presentAttendeeId);
        }
      };

      meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);

      const observer = {
        videoTileDidUpdate: (tileState) => {
          if (!tileState.boundAttendeeId) {
            return;
          }
          if (tileState.localTile) {
            meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement);
            this.videoOn = tileState.active;
          } else {
            meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElementAnother);
          }
        }
      };

      meetingSession.audioVideo.addObserver(observer);

      meetingSession.audioVideo.start();

      const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();

      if (this.$route.query.isVideoOn === "true" || this.$route.query.isVideoOn === true) {
        await meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);

        meetingSession.audioVideo.startLocalVideoTile();
        this.videoOn = true;
      } else {
        this.videoOn = false;
      }

      if (this.$route.query.isAudioOn !== "true" && this.$route.query.isAudioOn !== true) {
        meetingSession.audioVideo.realtimeMuteLocalAudio();
        this.audioOn = false;
      } else {
        this.audioOn = true;
      }

      this.meetingSession = meetingSession;
    },

    changeAudio() {
      if (this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()) {
        this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
      } else {
        this.meetingSession.audioVideo.realtimeMuteLocalAudio();
      }
      this.audioOn = !this.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
    },

    async changeVideo() {
      if (this.videoOn) {
        await this.meetingSession.audioVideo.stopLocalVideoTile();
        await this.meetingSession.audioVideo.stopVideoInput();
      } else {
        const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

        await this.meetingSession.audioVideo.startVideoInput(videoInputDevices[0].deviceId);
        this.meetingSession.audioVideo.startLocalVideoTile();
      }
    },

    async leaveCall() {
      await this.meetingSession.audioVideo.stop();
      await this.meetingSession.audioVideo.stopVideoInput();
      await this.$router.push({ name: "Sessions" });
    }
  },

  async mounted() {
    await this.$store.dispatch("sessions/getVideoRoom", this.$route.query.videoRoomId);

    await this.createAwsChimeVideo();

    window.addEventListener("beforeunload", async () => {
      await this.meetingSession.audioVideo.stop();
      await this.meetingSession.audioVideo.stopVideoInput();
    });
  },

  async beforeDestroy() {
    await this.meetingSession.audioVideo.stop();
    await this.meetingSession.audioVideo.stopVideoInput();
  },
  async beforeRouteLeave(to, from, next) {
    await this.meetingSession.audioVideo.stop();
    await this.meetingSession.audioVideo.stopVideoInput();
    return next();
  }
};
</script>

<style scoped>
#localTrack {
  @apply absolute;
  min-width: 220px;
  max-width: 250px;
  min-height: 140px;
  right: 2rem;
  top: 6rem;
  border-radius: var(--global-border-radius);
  overflow: hidden;
}

#localTrack:hover {
  opacity: 1;
}

#localTrack video {
  border-radius: 6px;
}

#remoteTrack {
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
}

.video-controls {
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  backdrop-filter: blur(40px);
  user-select: none;
}

.h-f {
  height: calc(100vh - 64px);
}

.audio-disabled {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6rem;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(40px);
}
</style>
