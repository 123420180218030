<template>
  <div
    class="cursor-pointer"
    @click="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <svg v-if="!full" fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8504 21.0933L34.5571 20.1133L30.1471 9.73C29.3538 7.84 26.6471 7.84 25.8538 9.73L21.4438 20.1367L10.1738 21.0933C8.12043 21.2567 7.28043 23.8233 8.84376 25.1767L17.4071 32.5967L14.8404 43.61C14.3738 45.6167 16.5438 47.2033 18.3171 46.13L28.0004 40.2967L37.6838 46.1533C39.4571 47.2267 41.6271 45.64 41.1604 43.6333L38.5938 32.5967L47.1571 25.1767C48.7204 23.8233 47.9038 21.2567 45.8504 21.0933ZM28.0004 35.9333L19.2271 41.23L21.5604 31.2433L13.8138 24.5233L24.0338 23.6367L28.0004 14.2333L31.9904 23.66L42.2104 24.5467L34.4638 31.2667L36.7971 41.2533L28.0004 35.9333Z"
        class="fill-primary"
      />
    </svg>

    <svg v-else fill="none" height="56" viewBox="0 0 56 56" width="56" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.6696 23.3347L30.2396 12.0414C29.563 9.82474 26.4363 9.82474 25.783 12.0414L22.3296 23.3347H11.9463C9.68295 23.3347 8.74962 26.2514 10.593 27.5581L19.0863 33.6247L15.7496 44.3814C15.073 46.5514 17.593 48.3014 19.3896 46.9247L27.9996 40.3914L36.6096 46.9481C38.4063 48.3247 40.9263 46.5747 40.2496 44.4047L36.913 33.6481L45.4063 27.5814C47.2496 26.2514 46.3163 23.3581 44.0529 23.3581H33.6696V23.3347Z"
        class="fill-primary"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "RatingStar",
  props: {
    full: Boolean
  }
};
</script>

<style scoped>
.fill-primary {
  fill: var(--primary-background);
}
</style>
