<template>
  <div class="min-h-screen" :class="$route.name === 'Home' ? 'neutral-background-1' : 'neutral-background-3'">
    <div class="w-full h-16 flex justify-between items-center px-24 neutral-background-2 shadow-md col-span-7">
      <NavBar />
      <div class="flex items-center">
        <p class="mr-12 font-semibold cursor-pointer body-font" @click="$modal.show('language')">{{ getLocale }}</p>
        <primary-button @click="logout">{{ $t("logout.button") }}</primary-button>
      </div>
    </div>

    <RateAppModal />
    <router-view />
  </div>
</template>

<script>
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { mapState } from "vuex";
import RateAppModal from "@/components/rate-app/RateAppModal";
import NavBar from "@/components/NavBar";

export default {
  name: "Main",
  components: { NavBar, RateAppModal, PrimaryButton },
  computed: {
    ...mapState("sessions", ["lastSession"]),
    ...mapState("locale", ["locale"]),
    getLocale() {
      let locale;
      switch (this.locale) {
        case "en-US":
          locale = "English";
          break;
        case "fr-FR":
          locale = "Français";
          break;
        case "it-IT":
          locale = "Italiano";
          break;
        case "de-DE":
          locale = "Deutsch";
          break;
      }
      return locale;
    }
  },

  watch: {
    async $route() {
      await this.$store.dispatch("sessions/getLastSession");
      if (this.lastSession && !this.lastSession?.app_feedback_coach) {
        this.$modal.show("rate-app");
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style scoped></style>
