import { render, staticRenderFns } from "./DateFilter.vue?vue&type=template&id=0527d097&scoped=true"
import script from "./DateFilter.vue?vue&type=script&lang=js"
export * from "./DateFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0527d097",
  null
  
)

export default component.exports