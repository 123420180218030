<template>
  <div class="w-full flex flex-col justify-center items-center body-font">
    <div class="w-full flex justify-between items-center mb-8">
      <title-text>{{ $t("sign.in.generic") }}</title-text>
    </div>
    <main-input :errors="emailErrors" class="w-full mb-4">
      <input v-model="email" :placeholder="$t('email.generic')" class="w-full" type="email" @blur="$v.email.$touch()" />
    </main-input>
    <main-input
      :errors="passwordErrors"
      class="w-full"
      password
      @change-to-text="passwordType = 'text'"
      @change-to-password="passwordType = 'password'"
    >
      <input
        v-model="password"
        :placeholder="$t('password.generic')"
        :type="passwordType"
        class="w-full"
        @blur="$v.password.$touch()"
      />
    </main-input>
    <caption-text class="mt-2 mb-4 text-right w-full">
      <router-link :to="{ name: 'ForgotPassword' }" class="interactive-text">
        {{ $t("forgot.password") }}
      </router-link>
    </caption-text>

    <primary-button :disabled="$v.$anyError" @click="signIn">{{ $t("sign.in.button") }}</primary-button>
  </div>
</template>

<script>
import TitleText from "@/components/ui-kit/typography/TitleText";
import MainInput from "@/components/ui-kit/form/MainInput";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import CaptionText from "@/components/ui-kit/typography/CaptionText";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "SignIn",
  components: { CaptionText, PrimaryButton, MainInput, TitleText },
  data() {
    return {
      passwordType: "password",
      email: "",
      password: ""
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t("email.required"));
      !this.$v.email.email && errors.push(this.$t("email.valid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("password.required"));
      !this.$v.password.minLength && errors.push(this.$t("password.longer", { count: "7" }));
      !this.$v.password.maxLength && errors.push(this.$t("password.shorter", { count: "31" }));
      return errors;
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(30)
    },
    email: {
      required,
      email
    }
  },

  methods: {
    async signIn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      await this.$store.dispatch("auth/signIn", { email: this.email, password: this.password });
    }
  }
};
</script>

<style scoped></style>
