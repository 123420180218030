<template>
  <div class="flex justify-start items-center body-font">
    <router-link class="text-main font-black text-2xl main-font" to="/profile">{{ logoLabel }}</router-link>
    <router-link active-class="active-tab interactive-text" class="ml-12 highlight inactive-tab" to="/profile">
      {{ $t("navbar.profile") }}
    </router-link>
    <router-link
      :to="{ name: 'Sessions' }"
      active-class="active-tab interactive-text"
      class="ml-12 highlight inactive-tab"
    >
      {{ $t("navbar.sessions") }}
    </router-link>
    <router-link
      :to="{ name: 'Availability' }"
      active-class="active-tab interactive-text "
      class="ml-12 highlight inactive-tab"
    >
      {{ $t("navbar.availability") }}
    </router-link>
    <router-link
      :to="{ name: 'SessionsLog' }"
      active-class="active-tab interactive-text"
      class="ml-12 highlight inactive-tab"
    >
      {{ $t("navbar.sessions.log") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      logoLabel: process.env.VUE_APP_LOGO_LABEL || "Humanoo"
    };
  }
};
</script>

<style scoped></style>
