import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import storageClient from "@/services/storageClient";

import Home from "@/views/Home.vue";
import Auth from "@/views/Auth";
import SignIn from "@/components/auth/SignIn";
import SignUp from "@/components/auth/SignUp";
import ForgotPassword from "@/components/auth/ForgotPassword";
import InitialSetup from "@/views/InitialSetup";
import Sessions from "@/views/Sessions";
import Main from "@/views/Main";
import Availability from "@/views/Availability";
import VideoSession from "@/views/VideoSession";
import SessionsLog from "@/views/SessionsLog";
import ResetPassword from "@/components/auth/ResetPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    async beforeEnter(to, from, next) {
      if (!storageClient.getToken()) {
        return next({ name: "SignIn" });
      }
      const user = store.getters["auth/user"];
      if ((user.languages && user.languages.length === 0) || !user.name || !user.expertise) {
        return next({ name: "InitialSetup" });
      }

      return next();
    },
    redirect: {
      name: "Home"
    },
    children: [
      {
        path: "/profile",
        name: "Home",
        component: Home
      },
      {
        path: "/sessions",
        name: "Sessions",
        component: Sessions
      },
      {
        path: "/availability",
        name: "Availability",
        component: Availability
      },
      {
        path: "/video-session",
        name: "VideoSession",
        component: VideoSession
      },
      {
        path: "/sessions-log",
        name: "SessionsLog",
        component: SessionsLog
      }
    ]
  },
  {
    path: "/initial-setup",
    name: "InitialSetup",
    component: InitialSetup,
    async beforeEnter(to, from, next) {
      if (!storageClient.getToken()) {
        return next({ name: "SignIn" });
      } else {
        if (!store.getters["auth/user"]) {
          await store.dispatch("auth/getUser");
        }
      }

      next();
    }
  },

  {
    path: "/",
    name: "Auth",
    component: Auth,
    beforeEnter(to, from, next) {
      if (storageClient.getToken()) {
        return next({ name: "Home" });
      }
      next();
    },
    redirect: {
      name: "SignIn"
    },
    children: [
      { path: "/sign-up", name: "SignUp", component: SignUp },
      { path: "/sign-in", name: "SignIn", component: SignIn },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        props: (route) => ({
          token: route.query.token,
          email: route.query.email
        }),
        component: ResetPassword
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (storageClient.getToken() && !store.getters["auth/user"]) {
    await store.dispatch("auth/getUser");
  }
  next();
});

export default router;
