<template>
  <h4 class="font-bold text-lg main-font primary-text">
    <slot></slot>
  </h4>
</template>

<script>
export default {
  name: "TitleText"
};
</script>

<style scoped></style>
