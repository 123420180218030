import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import { Vuelidate } from "vuelidate";
import Notifications from "vue-notification";
import VModal from "vue-js-modal";
import i18n from "@/i18n";

import VTooltip from "v-tooltip";
import { axiosSetup } from "@/axios";

Vue.use(VTooltip);

Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.use(VModal);

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

axiosSetup(Vue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
