<template>
  <modal :adaptive="true" :width="420" height="auto" name="language">
    <div class="py-4 px-8 flex flex-col body-font">
      <h3 class="text-center font-bold mb-6 text-lg main-font">{{ $t("languages.generic") }}</h3>

      <div
        :class="{ 'primary-background text-white': locale === 'en-US' }"
        class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 primary-background-active-hover cursor-pointer flex flex-row justify-between items-center"
        @click="selectLocale('en-US')"
      >
        <p class="text-base font-semibold">English</p>
      </div>

      <div
        :class="{ 'primary-background text-white': locale === 'de-DE' }"
        class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 primary-background-active-hover cursor-pointer flex flex-row justify-between items-center"
        @click="selectLocale('de-DE')"
      >
        <p class="text-base font-semibold">Deutsch</p>
      </div>

      <div
        :class="{ 'primary-background text-white': locale === 'fr-FR' }"
        class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 primary-background-active-hover cursor-pointer flex flex-row justify-between items-center"
        @click="selectLocale('fr-FR')"
      >
        <p class="text-base font-semibold">Français</p>
      </div>

      <div
        :class="{ 'primary-background text-white': locale === 'it-IT' }"
        class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 primary-background-active-hover cursor-pointer flex flex-row justify-between items-center"
        @click="selectLocale('it-IT')"
      >
        <p class="text-base font-semibold">Italiano</p>
      </div>

      <div class="flex justify-between items-center mt-4">
        <div></div>
        <secondary-button @click="$modal.hide('language')">{{ $t("cancel.generic") }}</secondary-button>
      </div>
    </div>
  </modal>
</template>

<script>
import SecondaryButton from "@/components/ui-kit/buttons/SecondaryButton";
import StorageClient from "@/services/storageClient";
import { mapState } from "vuex";

export default {
  name: "LanguageModal",
  components: { SecondaryButton },
  computed: {
    ...mapState("locale", ["locale"])
  },
  methods: {
    selectLocale(lang) {
      StorageClient.setLanguage(lang);
      this.$router.go(0);
    }
  }
};
</script>

<style scoped></style>
